<template>
  <div class="home">
    <div class="main-hero info">
      <Topnav />

      <div class="animation">
        <img src="../assets/shape1.svg" height="" width="" class="shape1" />
        <img src="../assets/shape2.svg" height="" width="" class="shape2" />
        <img src="../assets/shape3.svg" height="" width="" class="shape3" />
        <img src="../assets/shape4.svg" height="" width="" class="shape4" />
        <img src="../assets/shape5.svg" height="" width="" class="shape5" />
      </div>
    </div>
    <div class="back-info-box"></div>
    <div class="info-box-page">
      <h1>Terms of use</h1>
      <div class="txt">
        <p>
          These Terms and Conditions of Use (the "Terms of Use") apply to the
          Agmen Asset Management LLC. (hereafter: The Company) web site located
          at https://agmencapital.com, and all associated sites linked to it by
          Agmen Capital, it's subsidiaries and/or affiliates.This Site is owned
          and operated by the company. Please read these Terms of Use carefully
          before using the Site.
          <br />By accessing or using the Site or any of the content on the Site
          you agree to be legally bound by these Terms of Use. If you do not
          accept these Terms of Use, do not use the Site or any of the content
          (defined below). Agmen reserves the right, at its sole discretion, to
          change, modify, add or remove portions of these Terms of Use, at any
          time. It is your responsibility to check these Terms of Use
          periodically for changes. Your continued use of the Site following the
          posting of changes will mean that you accept and agree to the changes.
        </p>
      </div>
    </div>
    <div class="footer">
      <router-link to="/" class="footer-logo">
        <img src="../assets/Agmen-logo-dark.svg" height="" width="" />
      </router-link>
      <div class="footer-links-wrap">
        <a href="/#fund" class="footer-link">Our Firm</a>
        <router-link to="/team" class="footer-link">Team</router-link>

        <a href="/#portfolio" class="footer-link">Portfolio Companies</a>
        <a href="/#contact" class="footer-link">Contact Us</a>
        <router-link to="Privacypolicy" class="footer-link"
          >Privacy Policy</router-link
        >
        <router-link to="Termsofuse" class="footer-link"
          >Terms of Use</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openPopup: null,
      openSideBar: false,
    };
  },
};
</script>
