<template>
  <div class="home">
    <div class="main-hero info">
      <Topnav />

      <div class="animation">
        <img src="../assets/shape1.svg" height="" width="" class="shape1" />
        <img src="../assets/shape2.svg" height="" width="" class="shape2" />
        <img src="../assets/shape3.svg" height="" width="" class="shape3" />
        <img src="../assets/shape4.svg" height="" width="" class="shape4" />
        <img src="../assets/shape5.svg" height="" width="" class="shape5" />
      </div>
    </div>
    <div class="back-info-box"></div>
    <div class="info-box-page">
      <h1>Privacy policy</h1>
      <div class="txt">
        <p>This website user cookies to track basic analytics and usage.</p>
        <h1 class="">What are cookies?</h1>
        <p>
          Cookies are tiny text files stored on your computer when you visit
          certain web pages. At we use cookies to keep track of the options you
          have selected on the website and to remember you when you return to
          our site.
        </p>
        <p>
          To get the best experience, you need to have cookies enabled. If you
          don't wish to enable cookies, you will still be able to use the site.
        </p>
        <p>
          Cookies don't harm your computer. Like other online companies, we use
          cookies to provide you with more relevant and useful information.
          <span style="font-weight: 700">
            We don't store personally identifiable information such as credit
            card details in cookies we create,
          </span>
          but we do use information gathered from cookies to help improve your
          experience of the site.
        </p>
        <p>
          We use cookies to add features and analyse traffic. By using our site,
          you consent to our use of cookies. See below for more information
          about the cookies we use.
        </p>
        <h2 class="policies-subheadings">Cookies used on the website</h2>
        <h3 class="policies-subheader2">Google analytics</h3>
        <p>Here is a list of how we use cookies on the website</p>
        <p>Cookies:_ga</p>
        <p>
          We use Google Analytics to carry out statistical analysis of page use,
          page interactions and paths through the Website to evaluate and
          develop our Website. This is known as 'digital analytics'. We may also
          record certain information of customers during a Website purchase or
          other process.
        </p>
        <p>
          This information allows us to understand individual behaviours and
          needs more accurately.For more information on how Google uses the data
          collected via this service, see here:
          <a
            href="http://www.google.com/policies/privacy/partners/"
            rel="nofollow"
            target="_blank"
            >http://www.google.com/policies/privacy/partners/</a
          >
        </p>
        <p>
          To opt out of being tracked via Google Analytics, you can also use
          Google's opt-out browser add-on:
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            rel="nofollow"
            target="_blank"
            >https://tools.google.com/dlpage/gaoptout</a
          >
        </p>
      </div>
    </div>
    <div class="footer">
      <router-link to="/" class="footer-logo">
        <img src="../assets/Agmen-logo-dark.svg" height="" width="" />
      </router-link>
      <div class="footer-links-wrap">
        <a href="/#fund" class="footer-link">Our Firm</a>
        <router-link to="/team" class="footer-link">Team</router-link>

        <a href="/#portfolio" class="footer-link">Portfolio Companies</a>
        <a href="/#contact" class="footer-link">Contact Us</a>
        <router-link to="Privacypolicy" class="footer-link"
          >Privacy Policy</router-link
        >
        <router-link to="Termsofuse" class="footer-link"
          >Terms of Use</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openPopup: null,
      openSideBar: false,
    };
  },
};
</script>
